import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import AliceCarousel from "react-alice-carousel";
import "../../static/styles/alice-carousel.css";

import Img from "gatsby-image";
import { Layout } from "../elements/container";
import { Card } from "../elements/card";
import { Typography } from "../elements/Typography";
import { Image } from "../elements/Image";

const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SlideOverlay = styled(Card)`
  opacity: 0;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 200px;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear;
`;

const SlideImage = styled(Image)`
  border-radius: 0.5rem;
  height: 100%;
`;

// This slide is basically a container - any styling done on internal elements
const Slide = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 0.5rem;
  justify-content: center;
  font-size: 0;
  position: relative;
  padding: 0 1rem;

  &:hover ${SlideOverlay} {
    opacity: 1;
  }

  font-size: 1rem;
`;

const responsive = {
  0: {
    items: 1,
  },
  400: {
    items: 2,
  },
  700: {
    items: 3,
  },
  900: {
    items: 4,
  },
  1200: {
    items: 6,
  },
};

const settings = {
  autoPlay: false,
  buttonsDisabled: true,
  duration: 400,
  responsive,
  startIndex: 1,
};

export class CarouselMultipleSlides extends React.Component {
  render() {
    const { title, carousel } = this.props;
    return (
      <Layout style={{ padding: "12rem 0 8rem 0" }}>
        <Card margin="0 0 4rem 0">
          <Typography display3 heading2 centre bold noMargin>
            {title}
          </Typography>
          <Typography centre>
            Check out some of our work we are proud of.
          </Typography>
        </Card>
        <AliceCarousel {...settings}>
          {carousel.map((item, i) => (
            <Slide key={i}>
              <a
                href={`${item.case_study.guid}`}
                style={{ display: "flex", width: "230px", height: "200px" }}
              >
                <SlideContent>
                  {item.image.localFile.childImageSharp ? (
                    <Img
                      fixed={item.image.localFile.childImageSharp.fixed}
                      style={{
                        width: "230px",
                        height: "200px",
                        borderRadius: "0.5em",
                      }}
                    />
                  ) : (
                    <SlideImage
                      rounded
                      width="230"
                      height="200"
                      src={item.image.source_url}
                    />
                  )}

                  <SlideOverlay>
                    <Typography colorWhite bold centre noMargin>
                      {item.heading}
                    </Typography>
                    <Typography colorWhite display6 centre noMargin paddingLR>
                      {item.description}
                    </Typography>
                  </SlideOverlay>
                </SlideContent>
              </a>
            </Slide>
          ))}
        </AliceCarousel>
      </Layout>
    );
  }
}
