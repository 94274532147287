import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { CarouselMultipleSlides } from "../components/collections/CarouselMultipleSlides";

export const CarouselPannel = () => {
  const { wordpressAcfOptions } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          carousel {
            heading
            description
            case_study {
              guid
            }
            image {
              source_url
              title
              alt_text
              localFile {
                childImageSharp {
                  fixed(width: 230, height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { carousel } = wordpressAcfOptions.options;

  return <CarouselMultipleSlides title="Our work" carousel={carousel} />;
};
