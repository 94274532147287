import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { FadeIn } from "../containers/lazyLoad";

import theme from "../../styles/theme";
// import Layout from 'Layout';
import { Card } from "../elements/card";
import { Typography } from "../elements/Typography";
// import TextCard from 'TextCard';

import placeholder from "../../static/images/placeholder-square.png";

const ItemText = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  padding-bottom: 20%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear;
`;

const ItemImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%
  background-color: ${theme.color.grey.lightest};
`;

const GridCard = styled(Card)`
  width: 100%;
  height: 100%;
  &:hover ${ItemText} {
    opacity: 1;
  }
`;

// function dateConversion(dateString) {
//   const date = new Date(Date.parse(dateString));
//   const locale = "en-us";
//   return date.toLocaleString(locale, {
//     month: "long",
//     day: "numeric",
//     year: "numeric",
//   });
// }

export const GridItemPeople = props => {
  const {
    data: { node },
  } = props;

  let image; // Get featured image or else use placeholder
  if (
    !node.featured_media ||
    !node.featured_media.localFile ||
    !node.featured_media.localFile.childImageSharp.fixed
  ) {
    image = placeholder;
  } else {
    image = node.featured_media.localFile.childImageSharp.fixed.src;
  }

  return (
    <Link
      to={`/blog/${node.slug}`}
      style={{ display: "flex", width: "100%", height: "100%" }}
    >
      <GridCard>
        <ItemImage src={image} alt="Inspire Group" />
        <ItemText absolute>
          <Typography bold uppercase centre colorWhite noMargin paddingLR>
            {node.title}
          </Typography>
        </ItemText>
      </GridCard>
    </Link>
  );
};

export const GridItemWithTransition = props => (
  <GridItemPeople data={props.data} />
);
