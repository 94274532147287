import React, { Component } from "react";
import { graphql } from "gatsby";

import { CarouselMultipleSlides } from "../components/collections/CarouselMultipleSlides";
import Layout from "../components/Layout";
import { SiteTitle } from "../components/elements/SiteTitle";
import { Footer } from "../components/collections/Footer";
import { BlogGrid } from "../components/collections/BlogGrid";
import { Header } from "../components/collections/Header";
import { LogoPanel } from "../panels/logoPanel";
import { CarouselPannel } from "../panels/carouselPannel";
import { Builder } from "../components/collections/Builder";
import { BlogPanel } from "../panels/blogPanel";

export class Blog extends Component {
  render() {
    const { data } = this.props;

    const header = {
      textColor: data.wordpressPage.acf.header_text_color,
      isParallax: data.wordpressPage.acf.header_parallax,
      image: {
        ...data.wordpressPage.acf.header_image_bg,
      },
      blurredImage: data.wordpressPage.acf.header_image_blurred,
      video: data.wordpressPage.acf.header_video,
      backgroundType: data.wordpressPage.acf.header_background,
      title: this.props.data.wordpressPage.title,
    };

    if (
      data.wordpressPage.acf.header_image_bg &&
      data.wordpressPage.acf.header_image_bg.localFile &&
      data.wordpressPage.acf.header_image_bg.localFile.childImageSharp
    ) {
      header.image.src =
        data.wordpressPage.acf.header_image_bg.localFile.childImageSharp.original.src;
    }

    const { content_builder_page } = this.props.data.wordpressPage.acf;

    const blogposts = data.allWordpressPost.edges;

    return (
      <Layout>
        <SiteTitle>Blog</SiteTitle>
        <Header {...header} />
        <Builder content={content_builder_page} />
        <BlogPanel />
        <CarouselPannel />
        <LogoPanel />
        <Footer />
      </Layout>
    );
  }
}

export default Blog;

export const pageQuery = graphql`
  query blogQuery {
    wordpressPage(slug: { eq: "blog" }) {
      slug
      title
      acf {
        header_parallax
        header_image_bg {
          alt_text
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        header_image_blurred
        header_image_front
        header_text_colour
        header_video {
          source_url
          alt_text
          title
        }
        header_background
        content_builder_page {
          ... on WordPressAcf_grid_content {
            heading
            image {
              alt_text
              source_url
              title
              localFile {
                childImageSharp {
                  fixed(width: 500, height: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            text
            text_width
            layout
          }
          ... on WordPressAcf_text_block {
            id
            text
            heading
            text_size
          }
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          featured_media {
            source_url
            alt_text
            title
            localFile {
              childImageSharp {
                fixed(width: 500, height: 500) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;
