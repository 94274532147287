import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { Layout } from "../elements/container";

import { GridItemWithTransition } from "./GridItemPost";

const Grid = styled.ul`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
`;

const Item = styled.li`
  flex: 0 250px;
  height: 250px;
  width: 250px;
  position: relative;
  font-size: 0;
`;

export class BlogGrid extends React.Component {
  render() {
    const { data } = this.props; // Initial data

    return (
      <Layout>
        <Grid>
          {data.map((item, i) => (
            <Item key={i}>
              <GridItemWithTransition data={item} />
            </Item>
          ))}
        </Grid>
      </Layout>
    );
  }
}

BlogGrid.propTypes = {
  data: PropTypes.any.isRequired,
};
